import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const SEO = ({ children, location, description, title, image }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <Helmet
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
    >
      <html lang="es" />
      <title>{title}</title>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Raleway:wght@100;200;300;400;500&display=swap"
        rel="stylesheet"
      />

      {/* Fav icons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json"></link>

      {/* Meta tags */}
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="msapplication-TileColor" content="#377d4f" />
      <meta name="msapplication-TileImage" content="/favicon.svg" />
      <meta name="theme-color" content="#377d4f"></meta>
      <meta
        name="google-site-verification"
        content="ap214z6YimW_21zjrbFpC6q-quE9EESZNSbeCuEHWJo"
      />

      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || "/logo.png"} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={description} key="ogdesc" />
      {children}
    </Helmet>
  );
};

export default SEO;
